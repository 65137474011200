import * as React from "react";
import { Link } from "gatsby";

import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function CoverMeUp() {
  return (
    <Layout>
      <Helmet title="Cover Me Up | Jason Isbell" />

      <h1>Cover Me Up</h1>

      <h2>Jason Isbell</h2>

      <h4>Key: E</h4>

      <h4>Tempo: 62 (6/8)</h4>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>
            <Ch>E</Ch>A heart on the <Ch>E</Ch>run
          </p>
          <p>
            Keeps a hand on a <Ch>A</Ch>gun
          </p>
          <p>
            You can't trust <Ch>E</Ch>anyone
          </p>
          <p>
            I was so <Ch>E</Ch>sure
          </p>
          <p>
            What I needed was <Ch>A</Ch>more
          </p>
          <p>
            Tried to shoot out the <Ch>E</Ch>sun
          </p>
          <p>
            The days when we <Ch>C#m</Ch>raged, we flew off the <Ch>A</Ch>page
          </p>
          <p>
            Such damage was <Ch>E</Ch>done
          </p>
          <p>
            But I made it <Ch>E</Ch>through, 'cause somebody <Ch>A</Ch>knew
          </p>
          <p>
            I was meant for <Ch>E</Ch>someone
          </p>
        </Verse>
        <Chorus>
          <p>
            So <Ch>E</Ch>girl, leave your boots by the <Ch>A</Ch>bed
          </p>
          <p>
            We ain't leaving this <Ch>E</Ch>room
          </p>
          <p>
            'Til <Ch>E</Ch>someone needs medical <Ch>A</Ch>help
          </p>
          <p>
            Or the magnolias <Ch>E</Ch>bloom
          </p>
          <p>
            It's <Ch>F#m</Ch>cold in this house and I <Ch>A</Ch>ain't going out
            to chop <Ch>C#m</Ch>wood
          </p>
          <p>
            So cover me <Ch>Ebmaj7</Ch>up and know you're <Ch>A</Ch>enough
          </p>
          <p>
            To use me for <Ch>E</Ch>good
          </p>
        </Chorus>
        <Verse>
          <p>
            <Ch>E</Ch>I put your faith to the <Ch>E</Ch>test
          </p>
          <p>
            When I tore off your <Ch>A</Ch>dress
          </p>
          <p>
            In Richmond on <Ch>E</Ch>high
          </p>
          <p>
            I sobered <Ch>E</Ch>up, I swore off that <Ch>A</Ch>stuff
          </p>
          <p>
            Forever this <Ch>E</Ch>time
          </p>
          <p>
            And the old lover's <Ch>C#m</Ch>sing
          </p>
          <p>
            "I thought it'd be <Ch>A</Ch>me who helped him get <Ch>E</Ch>home"
          </p>
          <p>
            But home was a <Ch>E</Ch>dream
          </p>
          <p>
            One that I'd never <Ch>A</Ch>seen 'til you came <Ch>E</Ch>along
          </p>
        </Verse>
        <Chorus>
          <p>
            So <Ch>E</Ch>girl, hang your dress out to <Ch>A</Ch>dry
          </p>
          <p>
            We ain't leaving this <Ch>E</Ch>room
          </p>
          <p>
            'Til <Ch>E</Ch>Percy Priest breaks open <Ch>A</Ch>wide
          </p>
          <p>
            And the river runs <Ch>E</Ch>through
          </p>
          <p>
            And <Ch>F#m</Ch>carries this house on its <Ch>A</Ch>stones
          </p>
          <p>
            Like a piece of drift<Ch>C#m</Ch>wood
          </p>
          <p>
            So cover me <Ch>E</Ch>up and know you're <Ch>A</Ch>enough
          </p>
          <p>
            To use me for <Ch>E</Ch>good
          </p>
        </Chorus>
        <Chorus>
          <p>
            So <Ch>E</Ch>girl, leave your boots by the <Ch>A</Ch>bed
          </p>
          <p>
            We ain't leaving this <Ch>E</Ch>room
          </p>
          <p>
            'Til <Ch>E</Ch>someone needs medical <Ch>A</Ch>help
          </p>
          <p>
            Or the magnolias <Ch>E</Ch>bloom
          </p>
          <p>
            It's <Ch>F#m</Ch>cold in this house and I <Ch>A</Ch>ain't going out
            to chop <Ch>C#m</Ch>wood
          </p>
          <p>
            So cover me <Ch>Ebmaj7</Ch>up and know you're <Ch>A</Ch>enough
          </p>
          <p>
            To use me for <Ch>E</Ch>good
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}





